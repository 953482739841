<template>
  <v-dialog
    v-model="dialogDespesas"
    @click:outside="closeDespesas"
    @keydown.esc="closeDespesas"
    width="1450px"
    scrollable
  >
    <v-card min-height="450px">
      <v-card-title class="lightgray">
        <span class="primary--text font-weight-bold">
          {{ $tc("global.planoDeGastos") }}
        </span>

        <v-spacer></v-spacer>
        <v-btn icon @click="closeDespesas"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="d-flex pt-2 pb-6">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!desabilitarCompra && PDespesaAdicionar"
            @click="addDespesa()"
            class="success"
          >
            <v-icon>mdi-plus</v-icon>
            {{ $tc("global.add") }}
          </v-btn>
          <DialogAddEditDespesas
            v-if="dialogAddEditDespesas && PDespesaAdicionar"
            :dialogAddEditDespesas.sync="dialogAddEditDespesas"
            :edit="edit"
            :item="despesa"
            :moeda_despesa="moeda_despesa"
            :compra_id="compra_id"
            @fetch-despesas="getDespesas"
          />
        </div>

        <v-data-table
          :headers="despesas_headers"
          :items="despesas"
          :items-per-page="-1"
          disable-sort
          fixed-header
          height="270px"
          class="data-tables"
          hide-default-footer
        >
          <template v-slot:item.data_vencimento="{ item }">
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.cambio="{ item }">
            {{ item.moeda_valor_sigla }}

            <span v-if="item.moeda_valor_sigla === 'G$'">
              {{ item.cambio | guarani }}
            </span>
            <span v-else>
              {{ item.cambio | currency }}
            </span>
          </template>

          <template v-slot:item.valor_origem="{ item }">
            {{ item.moeda_valor_origem_sigla }}

            <span v-if="item.moeda_valor_origem_sigla === 'G$'">
              {{ item.valor_origem | guarani }}
            </span>
            <span v-else>
              {{ item.valor_origem | currency }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            {{ item.moeda_valor_sigla }}

            <span v-if="item.moeda_valor_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>

          <template v-slot:item.condpago="{ item }">
            <span v-if="item.condpago === 1">
              {{ $tc("global.aVista") }}
            </span>
            <span v-else>
              {{ $tc("global.aPrazo") }}
            </span>
          </template>
          <template v-if="!desabilitarCompra" v-slot:item.actions="{ item }">
            <template>
              <v-btn
                v-if="PDespesaEditar"
                icon
                small
                @click.stop="editDespesa(item)"
              >
                <v-icon size="20">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="pDespesaDeletar"
                color="red lighten-2"
                icon
                small
                @click.stop="deleteDespesaDialog(item)"
              >
                <v-icon size="20">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </template>
        </v-data-table>
        <DialogDeleteDespesa
          v-if="dialogDeleteDespesa"
          :dialogDeleteDespesa.sync="dialogDeleteDespesa"
          :despesa_id="despesa_id"
          @fetch-despesas="getDespesas"
        />
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-sheet
            rounded
            outlined
            height="40px"
            class="d-flex align-center subtitle-1 justify-space-between text--lighten-1 px-4 mr-2"
          >
            {{ $tc("global.total") + " " + "R$ " }}
            <span>
              {{ totalReal | currency }}
            </span>
          </v-sheet>
          <v-sheet
            rounded
            outlined
            height="40px"
            class="d-flex align-center subtitle-1 justify-space-between text--lighten-1 px-4 mr-2"
          >
            {{ $tc("global.total") + " " + "U$" }}
            <span>
              {{ totalDolar | currency }}
            </span>
          </v-sheet>
          <v-sheet
            rounded
            outlined
            height="40px"
            class="d-flex align-center subtitle-1 justify-space-between text--lighten-1 px-4 mr-2"
          >
            {{ $tc("global.total") + " " + "G$" }}

            <span>
              {{ totalGuarani | guarani }}
            </span>
          </v-sheet>
          <v-sheet
            rounded
            outlined
            height="40px"
            class="d-flex align-center subtitle-1 justify-space-between text--lighten-1 px-4"
          >
            {{
              $tc("global.total") +
              " " +
              $tc("global.valor") +
              " " +
              $tc("global.despesa")
            }}
            {{ moeda_despesa.sigla }}
            <span v-if="moeda_despesa.sigla === 'G$'">
              {{ totalGeralDespesas | guarani }}
            </span>
            <span v-else>
              {{ totalGeralDespesas | currency }}
            </span>
          </v-sheet>
        </div>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!desabilitarCompra"
          color="primary"
          class="white--text"
          @click="closeDespesas"
        >
          <v-icon left>mdi-check</v-icon>
          confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchDespesas } from "@/api/compras/compras_despesas.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "DialogDespesas",

  props: {
    dialogDespesas: {
      type: Boolean,
      default: false,
    },
    compra_id: {
      type: Number,
    },
    compra_status: {
      type: Number,
    },
    moeda_despesa: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      despesas: [],
      despesa: {},
      dialogAddEditDespesas: false,

      dialogDeleteDespesa: false,
      despesa_id: false,
    };
  },

  components: {
    DialogAddEditDespesas: () => import("./DialogAddEditDespesas.vue"),
    DialogDeleteDespesa: () => import("./DialogDeleteDespesa.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PDespesaAdicionar() {
      return this.getAccess("PlanoDespesas", "adicionar");
    },
    PDespesaEditar() {
      return this.getAccess("PlanoDespesas", "editar");
    },
    pDespesaDeletar() {
      return this.getAccess("PlanoDespesas", "deletar");
    },

    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    despesas_headers() {
      let headers = [
        {
          text: "DOC",
          value: "ncontrole",
        },
        {
          text: "OBS",
          value: "obs",
        },
        {
          text: this.$tc("global.tipo") + " " + this.$tc("global.despesa"),
          value: "compras_tipos_despesas_descricao",
        },
        {
          text: this.$tc("global.pagamento"),
          value: "condpago",
        },
        {
          text: this.$tc("global.vencimento"),
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.pagarpara"),
          value: "fornecedor_nome",
        },

        {
          text: this.$tc("global.cambio"),
          value: "cambio",
        },

        {
          text: this.$tc("global.valorOrigem"),
          value: "valor_origem",
          align: "end",
        },

        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "end",
        },
        {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        },
      ];
      return headers;
    },

    totalGeralDespesas() {
      let result = 0;

      if (this.despesas.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + Number(currentValue.valor);
        };

        result = this.despesas.reduce(reducer, 0);
      }
      return result;
    },

    totalReal() {
      let result = 0;

      if (this.despesas.length) {
        const reducer = (accumulator, currentValue) => {
          let valor = 0;
          if (currentValue.moeda_valor_origem_sigla === "R$") {
            valor = accumulator + Number(currentValue.valor_origem);
          }
          return valor;
        };

        result = this.despesas.reduce(reducer, 0);
      }
      return result;
    },

    totalDolar() {
      let result = 0;

      if (this.despesas.length) {
        let valor = 0;
        const reducer = (accumulator, currentValue) => {
          if (currentValue.moeda_valor_origem_sigla === "U$") {
            valor = accumulator + Number(currentValue.valor_origem);
          }
          return valor;
        };

        result = this.despesas.reduce(reducer, 0);
      }
      return result;
    },
    totalGuarani() {
      let result = 0;

      if (this.despesas.length) {
        let valor = 0;
        const reducer = (accumulator, currentValue) => {
          if (currentValue.moeda_valor_origem_sigla === "G$") {
            valor = accumulator + Number(currentValue.valor_origem);
          }
          return valor;
        };

        result = this.despesas.reduce(reducer, 0);
      }
      return result;
    },
    desabilitarCompra() {
      let result = false;

      if (this.compra_status === 2) {
        result = true;
      }
      if (this.compra_status === -1) {
        result = true;
      }
      if (this.compra_status === -2) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    closeDespesas() {
      if (!this.desabilitarCompra) {
        this.$emit("update:valorDespesas", this.totalGeralDespesas);
        this.$emit("calcular-cif");
      }
      this.$emit("update:dialogDespesas", false);
    },

    addDespesa() {
      this.dialogAddEditDespesas = true;
      this.edit = false;
    },

    editDespesa(item) {
      this.dialogAddEditDespesas = true;
      this.despesa = { ...item };
      this.edit = true;
    },

    getDespesas() {
      this.loading = true;
      fetchDespesas(`?compra_id=${this.compra_id}`)
        .then((response) => {
          this.despesas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteDespesaDialog(item) {
      this.dialogDeleteDespesa = true;
      this.despesa_id = item.id;
    },
  },

  mounted() {
    this.getDespesas();
  },
};
</script>

<style></style>
